<template>
  <div class="orderExpand">
    <el-card v-if="Order.channel != 3">
      <el-table :data="orderItem_list">
        <!-- :summary-method="getSummaries"
        show-summary -->
        <el-table-column prop="title" label="项目名称" width="180"> </el-table-column>
        <el-table-column prop="copies" label="份数"> </el-table-column>
        <el-table-column prop="count" label="数量"> </el-table-column>
        <el-table-column prop="unit" label="单位"> </el-table-column>
        <el-table-column prop="price" label="单价"> </el-table-column>

        <!-- <el-table-column prop="total_price" label="小计">
        <template #default="scope"> {{ total_price(scope) }}元 </template>
      </el-table-column> -->

        <el-table-column prop="need" label="备注" min-width="150"> </el-table-column>
      </el-table>
    </el-card>
    <div v-if="Order.channel == 3">
      <el-descriptions class="margin-top descriptions" title="订单详情" :column="3" :size="size" border>
        <template #extra>
          <!-- <el-upload class="upload-demo" action="http://192.168.1.12:13000/voucher" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList" :headers="headers">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload> -->
          <el-button type="primary" @click="click_downloadAllFile2(10)">下载文件备用</el-button>
          <el-button type="primary" @click="click_psc_update()">更新下载网盘链接</el-button>
          <el-button type="primary" @click="click_addServiceTips()">添加客服信息</el-button>
          <!-- <el-button type="primary" :disabled="Order.printing != 1 || Order.status > 29">自动打印</el-button> -->
          <el-button type="primary" @click="click_downloadAllFile2(2)">自动打印</el-button>
          <el-button type="primary" @click="click_startPrint()" :disabled="Order.printing == 1 || Order.status > 29"> 开始打印
          </el-button>
          <el-button type="primary" @click="click_downloadAllFile()">下载文件</el-button>

          <el-button type="primary" @click="click_downloadAllFile2()">下载文件|分类</el-button>
          <el-button type="primary" @click="click_sendOutGoods()" :disabled="Order.printing != 1 || Order.status >= 30">
            发货</el-button>
        </template>

        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              收件人
            </div>
          </template>
          {{ Address.receiver }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <iphone />
              </el-icon>
              联系电话
            </div>
          </template>
          {{ Address.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <location />
              </el-icon>
              城市
            </div>
          </template>
          {{ Address.city }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <tickets />
              </el-icon>
              Remarks
            </div>
          </template>
          <el-tag size="small">School</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <office-building />
              </el-icon>
              收货地址
            </div>
          </template>
          {{ Address.full }} {{ Address.detail }} {{ Address.phone }} {{ Address.receiver }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="expressInfo" v-if="Order.expressNumber">物流单号:{{ Order.expressNumber }}</div>
      <div class="expressInfo" v-if="Order.serviceTips"><el-tag>客服信息</el-tag> {{ Order.serviceTips }}</div>

      <el-card class="Files" v-if="File_list">
        <el-table :data="File_list">
          <el-table-column label="文件名" prop="filename" min-width="160" show-overflow-tooltip />
          <el-table-column label="打印材料" width="200">
            <template #default="scope">
              【{{ scope.row.size }}】【{{ scope.row.style }}】【{{ scope.row.double }}】
              <div>{{ scope.row.fs_id }}</div>
            </template>
          </el-table-column>
          <el-table-column label="打印材料" width="150">
            <template #default="scope">
              <div>{{ scope.row.material }}</div>
              <el-tag v-if="scope.row.scale != '每版打印1页'"> {{ scope.row.scale }}</el-tag>
              <div>{{ scope.row.pcs_path }}</div>

              <div v-if="scope.row.pageMin != 1 || scope.row.pageMax != scope.row.pageCount">
                <el-tag type="danger"><span>{{ scope.row.pageMin }}</span>
                  -
                  <span>{{ scope.row.pageMax }}</span> 页</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="文件信息" width="100">
            <template #default="scope">
              <div v-if="scope.row.fileSize"> {{ Math.round((scope.row.fileSize / 1024 / 1024) * 10) / 10 }} M</div>
            </template>
          </el-table-column> -->

          <el-table-column label="价格" width="110">
            <template #default="scope"> {{ scope.row.totalPrice }} 元 </template>
          </el-table-column>
          <!-- <el-table-column label="缩放" prop="scale" width="120" /> -->

          <el-table-column label="页数" prop="pageCount" width="80" />
          <el-table-column label="份数" prop="count" width="80" />
          <el-table-column label="装订方式" width="120">
            <template #default="scope">
              {{ scope.row.binding }}
              <div v-if="scope.row.binding == '皮纹纸胶装'">{{ scope.row.coverColor }}</div>
              <div v-if="scope.row.binding == '皮纹纸胶装'">{{ scope.row.coverContent }}</div>
              <div v-if="scope.row.coverText">印字： {{ scope.row.coverText }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button @click="click_copyUrl(scope.row)">复制下载链接</el-button>
            </template>
          </el-table-column>

          <!-- <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button @click="handle_downFile(scope.row)">下载文件</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script setup >


import Api from '@/network'
import { request } from '@/network/request'
import { onMounted, reactive, ref } from 'vue'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
const pageData = reactive(Api.adminPage('order'))

const props = defineProps({
  Order: { type: Object, default: true },
})

pageData.row = props.Order

const File_list = props.Order.Files
if (!props.Order.Address) {
  props.Order.Address = JSON.parse(props.Order.addressJSON)
}
const Address = props.Order.Address

const orderItem_list = props.Order.orderItem_list

const click_sendOutGoods = async () => {
  ElMessageBox.prompt('请输入物流单号', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(({ value }) => {
    ElMessage({
      type: 'success',
      message: value
    });
    pageData.row.expressNumber = value
    pageData.row.status = 30
    pageData.update()

  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消输入'
    });
  });
}

const click_addServiceTips = async () => {
  ElMessageBox.prompt('请输入客服信息', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(({ value }) => {
    ElMessage({
      type: 'success',
      message: value
    });
    pageData.row.serviceTips = value
    pageData.update()
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消输入'
    });
  });
}
const click_downloadAllFile2 = async (type) => {

  await click_psc_update()
  let reFiles = []
  let url_list = File_list.map(File => {
    // File.url = File.url.replace("https://te.ali.yuancong1999.top","http://192.168.1.18:13000")
    let twoDir = `\\【${File.double}】【${File.count}份】【${File.binding}】`

    if (File.binding == "皮纹纸胶装") {
      twoDir = twoDir + `【${File.coverColor}】`
      if (File.coverContent == "文件首页为封面") {
        twoDir = twoDir + `【${File.coverContent}】`
      }
      if (File.coverContent == "文字封面") {
        twoDir = twoDir + `印字：`
        twoDir = twoDir + `印字：【${File.coverText}】`
      }
    }
    // let twoDir = "\\" + File.double + "——" + File.size + "——" + File.count + " 份" + "——" + File.binding
    let deepDir = `【${File.style}】【${File.size}】【${File.material}】` + twoDir
    if (File.scale != "每版打印1页") {
      deepDir = `【${File.scale}】` + deepDir
    }
    // ===================== 文件添加页数显示
    let new_filename = `【${File.pageCount}页】` + File.filename
    //   let new_filename = File.filename
    if (File.range != "全部") {
      new_filename = `选页码打印【${File.pageMin}-${File.pageMax} 页】` + new_filename
    }

    // =====================文件添加页数显示
    let printerBlack = "黑白2号"
    //  printerBlack = "Canon iR-ADV 8205 UFR II (副本 3)"
    if (File.binding == "订书针") {
      printerBlack = "黑白2号(左侧装订)"
      //  printerBlack = "黑白1号"
    }
    if (File.binding == "不装订" && File.pageCount >= 35 && File.pageCount < 200) {
      printerBlack = "黑白2号(左侧装订)"
      //  printerBlack = "黑白1号"
    }

    let printerColor = "彩色1号"
    if (File.color == "激光彩色") printerColor = "彩色1号"
    let dmPaperSize = 9
    if (File.size == 'A3') dmPaperSize = 8
    if (File.size == 'B5') dmPaperSize = 13

    if (type == 10) new_filename = File.id + "." + File.Extension
    let objFile = {
      ...File,
      url: File.url,
      filename: new_filename,
      //  filename: Math.random(),
      deepDir: deepDir,
      Extension: File.Extension,
      dmDuplex: File.double == "单面" ? 1 : 2,
      printer: File.style == "黑白" ? printerBlack : printerColor,
      printer2: "Canon iR-ADV 8205 UFR II (装订：横向右上角单钉)",
      dmPaperSize: dmPaperSize,
      dmCopies: File.count,
    }
    for (let refile of reFiles) {
      if (refile.filename == objFile.filename) {
        objFile.filename = `${Math.round(Math.random() * 100)}` + objFile.filename
      }
    }
    reFiles.push(objFile)
    return objFile
  })
  Address.receiver = Address.receiver.replace(" ", "")
  Address.receiver = Address.receiver.replace(" ", "")
  Address.receiver = Address.receiver.replace(" ", "")
  let address2 = Address.full + " " + Address.receiver
  let address3 = Address.full + " " + Address.detail + " " + Address.phone + " " + Address.receiver


  let downloadRes2 = await axios({
    url: 'http://127.0.0.1:12000',
    method: 'POST',
    data: JSON.stringify(url_list),
    params: {
      orderNumber: escape(address2),
      address3: escape(address3),
      price: pageData.row.amount,
      //   filesJson: escape(JSON.stringify(url_list)),
      operation: type == 2 ? "downloadFiles2AndFile" : "downloadFiles2"
    }
  })

}
const click_downloadAllFile = async () => {

  await click_psc_update()


  setTimeout(async () => {
    let deepDir = ""
    let url_list = File_list.map(File => {

      return {
        url: File.url,
        filename: File.filename,
        Extension: File.Extension,
        dmDuplex: File.double == "单面" ? 1 : 2,
      }
    })
    let downloadRes = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'POST',
      params: {
        orderNumber: escape(pageData.row.number),
        filesJson: escape(JSON.stringify(url_list)),
        deepDir: escape(JSON.stringify(deepDir)),
        operation: "downloadFiles",

      }
    })
  }, 5200)
  // for (let File of File_list) {
  //   setTimeout(() => {
  //     window.location.href = File.url
  //   }, 1000)
  // }
}
const click_startPrint = () => {
  pageData.row.printing = 1
  pageData.update()
}


const handle_downFile = (file) => {

  window.open(file.url)
}
const click_copyUrl = (file) => {
  //拿到想要复制的值
  let copyInput = document.createElement('input');//创建input元素
  document.body.appendChild(copyInput);//向页面底部追加输入框
  copyInput.setAttribute('value', file.url);//添加属性，将url赋值给input元素的value属性
  copyInput.select();//选择input元素
  document.execCommand("Copy");//执行复制命令
  ElMessage({
    type: 'success',
    message: '复制下载地址成功'
  });
  copyInput.remove()
}


const click_psc_update = async () => {

  let isPcS = false
  for (let file of File_list) {
    if (file.uploadMethod == 'pcs') {
      isPcS = true
    }
  }
  if (!isPcS) {
    ElMessage({
      type: 'success',
      message: '开始下载文件'
    });
    return 0;
  }

  let update_dlinkRes = await request({
    url: '/update_dlink',
    method: 'POST',
    data: {
      file_list: File_list
    }
  })
  if (update_dlinkRes.code != -1) {
    console.log(update_dlinkRes.list)
    ElMessage({
      type: 'success',
      message: '下载链接更新成功'
    });
    for (let File of File_list) {
      for (let dfile of update_dlinkRes.list) {
        if (File.fs_id == dfile.fs_id) {
          File.url = dfile.dlink + `&access_token=${store.state.initData.pcs_accessToken}`
        }
      }
    }
    console.log(File_list)
  } else {
    // ElMessage({
    //   type: 'danger',
    //   message: update_dlinkRes.message
    // });
  }
  return 0
}
const headers = {
  accessToken: window.localStorage.getItem('accessToken'),
  order: props.Order.id
}
const handleRemove = (file) => {
  console.log(file, fileList);
}
const handlePreview = (file) => {
  console.log(file);
}
const handleExceed = (file) => {
  this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
}
const beforeRemove = (file) => {
  return this.$confirm(`确定移除 ${file.name}？`);
}

</script>

<style lang="less">
.orderExpand {
  max-width: 1100px;
  //  margin: 50px auto;
  margin-bottom: 200px;

  .descriptions {
    margin-bottom: 15px;
  }
}

.expressInfo {
  background-color: rgb(240, 240, 240);
  margin: 10px 0px;
  padding: 20px;
  font-size: 17px;
}
</style>
